<script>
  /**
   * errorMessage is a global store, so when navigating between pages the error state persists
   * unless it is manually cleared on each page.
   *
   * This clears the error state on a route change such that each page does not need to clear any
   * previous error messages.
   *
   * This also clears the breadcrumb paths in the same manner.
   */

  import { getContext, onDestroy } from 'svelte';
  import { ROUTER } from 'svelte-routing/src/contexts';
  import { errorMessage, breadcrumbPaths } from '../stores/core-store';
  import { setupGoogleAnalytics, sendGoogleAnalyticsPageView } from '../util/google-analytics';
  import { GTM_ID } from '../config';
  import { getQueryParameter } from '../util/util';

  const { activeRoute } = getContext(ROUTER);

  setupGoogleAnalytics(GTM_ID);

  let lastPath = undefined;
  const sessionIdRoutes = ['/sessions/plans/view', '/sessions/view', '/sessions/lobby', '/sessions/join'];

  const unsubscribe = activeRoute.subscribe((route) => {
    // Reset scroll position on navigate
    window.scrollTo(0, 0);

    if (route) {
      if (route.route.path !== lastPath) {
        errorMessage.set('');
        breadcrumbPaths.set([]);
        lastPath = route.route.path;

        let tabValue = getQueryParameter('tab');
        tabValue = tabValue === 'qr' || tabValue === 'numerical' ? tabValue : undefined;

        let sessionId = undefined;
        const sessionIdRouteHit = sessionIdRoutes.find((sessionIdRoute) => route.uri.startsWith(`${sessionIdRoute}`));
        if (sessionIdRouteHit) {
          sessionId = route.uri.replace(`${sessionIdRouteHit}/`, '');
        }

        sendGoogleAnalyticsPageView(sessionId, tabValue);
      }
    }
  });

  onDestroy(unsubscribe);
</script>

<style>
</style>
