<script>
  import { onMount } from 'svelte';
  import Cookies from 'js-cookie';
  import { getJwtPayload } from '../util/json-web-tokens';
  import { getQueryParameter } from '../util/util';
  import { decodeMasqueradeData, startMasquerading, stopMasquerading } from '../util/masquerade';
  import { Logger } from '../util/logs';
  import { setJsonWebTokenRefreshTimer } from '../util/api';
  import { createAccountObjectFromPayload } from '../util/helpers/account-helpers';
  import { account, errorMessage, isAuthenticated } from '../stores/core-store';
  import { sendUnityJsonWebTokenMessage, sendUnityLogoutMessage } from '../util/unity';
  import { navigate } from 'svelte-routing';

  onMount(() => {
    const pathname = window.location.pathname;
    const isPublic = pathname === '/' || pathname === '/login';
    const queryParams = window.location.search;
    const redirectTo = `${pathname}${queryParams}`;
    const jwt = Cookies.get('jwt');
    if (jwt) {
      const payload = getJwtPayload(jwt);

      // Masquerade
      const masqueradeData = getQueryParameter('m');
      if (masqueradeData) {
        // Check query parameter first
        const institution = decodeMasqueradeData(masqueradeData);
        startMasquerading(institution);
        history.replaceState({}, '', '/');
        Logger.log('Masquerade data found in query parameter.');
      } else {
        // Check session storage if no masquerade data is in the query param
        const institution = sessionStorage.getItem('masquerade');
        if (institution) {
          startMasquerading(JSON.parse(institution));
          Logger.log('Masquerade data found in session storage.');
        }
      }

      if (Date.now() / 1000 < payload.exp) {
        Logger.log('Valid JWT found in cookies, logging in...');
        setJsonWebTokenRefreshTimer();
        account.set(createAccountObjectFromPayload(payload));
        isAuthenticated.set(true);
        sendUnityJsonWebTokenMessage();
        if (pathname === '/') navigate('/dashboard');
      } else {
        Logger.log('JWT expired (detected client-side)!');
        errorMessage.set('You have been logged out due to inactivity. Please sign in again.');
        Cookies.remove('jwt');
        isAuthenticated.set(false);
        stopMasquerading();
        sendUnityLogoutMessage();
        navigate(`/login${!isPublic ? `?redirectTo=${redirectTo}` : ''}`);
      }
    } else {
      navigate(`/login${!isPublic ? `?redirectTo=${redirectTo}` : ''}`);
    }
  });
</script>
