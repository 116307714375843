import { get } from 'svelte/store';
import { isUnity } from './unity';
import { getMobileOperatingSystem } from './util';
import { institutionIdStore, institutionMasquerade, isAuthenticated } from '../stores/core-store';
import { realInstitutionStore } from '../stores/institutions-store';
import { Logger } from './logs';

let _gtmID = '';

function setupGoogleAnalytics(gtmID) {
  window.dataLayer = window.dataLayer || [];

  if (!gtmID) {
    console.warn('Google Analytics is disabled.');
    return;
  }

  Logger.log(`Setting up Google Tag Manager: ${gtmID}`);
  _gtmID = gtmID;
}
/**
 * Sends a page_view event to Google Analytics.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
 * @param {string} path
 */
function sendGoogleAnalyticsPageView(sessionId, tabValue) {
  const dimensions = getGoogleAnalyticsDimensions(sessionId, tabValue);
  window.dataLayer.push({
    event: 'page_view',
    ...dimensions,
  });
}

function getGoogleAnalyticsDimensions(sessionId, tabValue) {
  const dimensions = {};

  // Dimension1 = platform
  if (isUnity()) {
    const os = getMobileOperatingSystem();
    if (os === 'iOS') {
      dimensions.platform = 'iOS';
    } else if (os === 'Android') {
      dimensions.platform = 'Android';
    } else {
      dimensions.platform = 'Unknown';
    }
  } else {
    dimensions.platform = 'Web';
  }

  if (!get(isAuthenticated)) {
    return dimensions;
  }

  // Dimension2 = gigxr_institution_id
  const institutionId = get(institutionIdStore);
  if (institutionId) {
    dimensions['gigxr_institution_id'] = institutionId;
  }

  // Dimension3 = gigxr_institution_name
  const institution = get(realInstitutionStore);
  if (institution) {
    dimensions['gigxr_institution_name'] = institution.institutionName;
  }

  // Dimension4 = gigxr_session_id
  if (sessionId) {
    dimensions['gigxr_session_id'] = sessionId;
  }

  // Dimension5 = gigxr_headset_login_method
  if (tabValue) {
    dimensions['gigxr_headset_login_method'] = tabValue;
  }

  return dimensions;
}

export { setupGoogleAnalytics, sendGoogleAnalyticsPageView };
