<script>
  export let items = [];
  export let activeTab = 0;
  export let props = {};
  export let handleTabChange;

  const handleClick = (tabValue, id) => {
    activeTab = tabValue;
    if (handleTabChange && id) {
      handleTabChange(id);
    }
  };
</script>

<div class="tabs-container">
  <div class="tabs-wrapper">
    {#each items as item, index}
      <span
        class={(activeTab === index ? 'active ' : '') + 'tab'}
        on:click={handleClick(index, item.id)}
      >{item.label}</span>
    {/each}
  </div>
</div>
{#each items as item, index}
  {#if activeTab == index}
    <div class="tab-content-wrapper">
      <svelte:component this={item.component} {...props} />
    </div>
  {/if}
{/each}

<style>
  .tab-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 2rem 0 2rem;
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tabs-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    overflow: auto;
    border-radius: 2.5rem;
    background-color: #eeeef6;
    color: #8b8bc3;
    font-weight: 700;
    padding: 0.25rem;
  }

  .tab {
    display: block;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    text-align: center;
    line-height: 1.2;
  }

  .tab:hover {
    color: #6f6f9c;
  }

  .tab.active {
    background-color: white;
    border-radius: 2.5rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 480px) {
    .tab {
      min-width: 10rem;
    }
  }

  @media (min-width: 768px) {
    .tab {
      padding: 0.75rem 2rem;
    }

    .tabs-wrapper {
      padding: 0.5rem;
    }
  }
</style>
