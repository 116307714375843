<script>
  import { navigate } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
</script>

<Button
  id="headset-login-button"
  on:click={() => navigate('/headset-login')}
  variant="unelevated"
  class="headset-login-button"
>
  <Icon class="material-icons" />
  <Label class="headset-login-button__label">Headset Login</Label>
</Button>

<style>
  :global(.headset-login-button) {
    height: 75px;
  }

  :global(.headset-login-button .material-icons) {
    background-image: url('/assets/qr-icon.svg');
    background-repeat: no-repeat;
    background-size: 55px 55px;
    width: 55px;
    height: 55px;
  }

  :global(.headset-login-button__label) {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
</style>
