import { parse as uuidParse, stringify as uuidStringify } from 'uuid';
import * as Sentry from '@sentry/browser';

/**
 * Get a query parameter from the URL.
 *
 * @param {string} key
 * @return {string}
 */
function getQueryParameter(key) {
  const params = new URL(document.location).searchParams;

  return params.get(key);
}

/**
 * Set a query parameter in the URL.
 *
 * @param {string} key
 * @param {string} value
 */
function setQueryParameter(key, value) {
  const url = new URL(window.location);

  url.searchParams.set(key, value);

  window.history.replaceState(null, '', url);
}

/**
 * Returns a deep copy of an object.
 *
 * @param {Object} object
 * @returns {Object}
 */
function deepCopy(object) {
  // typeof null === 'object'; // true
  // Old ECMAScript bug that will never be fixed:
  // https://web.archive.org/web/20160331031419/http://wiki.ecmascript.org:80/doku.php?id=harmony:typeof_null
  if (typeof object !== 'object' || object === null) {
    throw new TypeError('object is not an Object!');
  }

  return JSON.parse(JSON.stringify(object));
}

/**
 * Just like Object.keys(), except the keys are sorted alphabetically.
 *
 * @param {Object} object
 * @returns {[string]}
 */
function objectSortedKeys(object) {
  return Object.keys(object).sort((a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

/**
 * Converts bytes to a human readable format.
 *
 * https://stackoverflow.com/a/14919494
 */
function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  const nonBreakingSpace = String.fromCharCode(160);

  if (Math.abs(bytes) < thresh) {
    return bytes + nonBreakingSpace + 'B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + nonBreakingSpace + units[u];
}

function bytesToGigabytes(bytes) {
  return Number(bytes) / 1000 / 1000 / 1000;
}

function gigabytesToBytes(gigabytes) {
  return Number(gigabytes) * 1000 * 1000 * 1000;
}

/**
 * Escape a string for matching in a regular expression.
 * @param {string} string
 */
function escapeRegExp(string) {
  // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Base64 encode a Unicode string.
 *
 * `btoa` only supports Latin1 characters.
 *
 * @see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param {string} str
 */
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }),
  );
}

/**
 * Base64 decode a Unicode string.
 *
 * `atob` only supports Latin1 characters.
 *
 * @see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param {string} str
 */
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
}

/**
 * Case-insensitive equality test of two UUID strings.
 *
 * @param {string} uuidA
 * @param {string} uuidB
 */
function uuidEqual(uuidA, uuidB) {
  try {
    const normalizedUuidA = uuidStringify(uuidParse(uuidA));
    const normalizedUuidB = uuidStringify(uuidParse(uuidB));

    return normalizedUuidA === normalizedUuidB;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export {
  getQueryParameter,
  setQueryParameter,
  deepCopy,
  objectSortedKeys,
  getMobileOperatingSystem,
  humanFileSize,
  bytesToGigabytes,
  gigabytesToBytes,
  escapeRegExp,
  b64EncodeUnicode,
  b64DecodeUnicode,
  uuidEqual,
};
