// Config for gigxr.com

export const BASE_API_URL = '/api/v1';
export const DEVELOPMENT = false;
export const GA_MEASUREMENT_ID = 'UA-167328436-1';
export const FIREBASE_DYNAMIC_LINK_URL = 'https://gigmobile.page.link';
export const ANDROID_PACKAGE_NAME = 'com.gigxr.gigmobile';
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = '4091cfbb-5c1a-48d5-99f9-bdb2c7f0301e';
export const SENTRY_ENVIRONMENT = 'prod';
export const BASE_CONVERSATIONS_URL = 'https://conversations.gigxr.dev/';
