export const APPS_MAPPING_BY_ID = {
  HOLO_HUMAN: '0debb0e0-76fa-483e-9594-68c6be0558cf',
  'HOLO_HUMAN+': '0f81f2e3-fe92-403f-ab3a-44e69e9e709c',
  HOLO_SCENARIOS: '5516eb34-611a-4eac-b2d6-834a8c1d4509',
  HOLO_PATIENT: '137dd6e6-38e2-4aba-a0d4-11e5cb8d0a62',
  HOLO_PATIENT_LEGACY: '0d9a8cc4-413f-4876-8e90-c1ad44b822e6',
  HOLO_CHEM_LAB: 'cf9c4260-83c2-4321-8df3-514f13ea5a27',
  CONVERSATIONS: 'c24ca108-edfb-4287-b1c2-6cfa4cad2230',
};

export const ICON_TO_APP_ID_MAPPING = {
  [APPS_MAPPING_BY_ID.HOLO_HUMAN]: 'holohuman.svg',
  [APPS_MAPPING_BY_ID['HOLO_HUMAN+']]: 'holohuman+.svg',
  [APPS_MAPPING_BY_ID.HOLO_SCENARIOS]: 'holoscenarios.svg',
  [APPS_MAPPING_BY_ID.HOLO_PATIENT]: 'holopatient.svg',
  [APPS_MAPPING_BY_ID.HOLO_PATIENT_LEGACY]: 'holopatient.svg',
  [APPS_MAPPING_BY_ID.HOLO_CHEM_LAB]: 'holochemlab.svg',
  [APPS_MAPPING_BY_ID.CONVERSATIONS]: 'conversations.svg',
  DEFAULT: 'gigxr-logo-white.svg',
};
