<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { account, isAuthenticated, errorMessage } from '../stores/core-store.js';
  import { getJwtPayload } from '../util/json-web-tokens.js';
  import Cookies from 'js-cookie';
  import Icon from '@smui/textfield/icon/index';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageErrorBanner from '../components/unauthenticated/UnauthenticatedPageErrorBanner.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import UnauthenticatedPageTextField from '../components/unauthenticated/UnauthenticatedPageTextField.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import UnauthenticatedPageRow from '../components/unauthenticated/UnauthenticatedPageRow.svelte';
  import { Logger } from '../util/logs';
  import { authenticate } from '../util/api/accounts';
  import { getQueryParameter } from '../util/util';
  import { createAccountObjectFromPayload } from '../util/helpers/account-helpers';
  import { decodeMasqueradeData, startMasquerading, stopMasquerading } from '../util/masquerade';
  import {
    isUnity,
    sendUnityJsonWebTokenMessage,
    sendUnityLogoutMessage,
    sendUnitySwitchEnvironmentMessage,
  } from '../util/unity';
  import { setJsonWebTokenRefreshTimer } from '../util/api.js';
  import TestEnvironmentIdentifier from '../components/TestEnvironmentIdentifier.svelte';

  let email = '';
  let password = '';
  let loginButtonDisabled = false;
  let keepMeLoggedIn = false;

  $: if (isUnity() && window.environmentCredentials.has(email)) {
    const secret = window.environmentCredentials.get(email);
    if (password === secret) {
      const environment = email;
      const switchConfirm = window.confirm(`Switch to ${environment} environment?`);
      if (switchConfirm) {
        sendUnitySwitchEnvironmentMessage(environment);
      }
    }
  }

  onMount(async () => {
    const jwt = Cookies.get('jwt');
    if (jwt) {
      const payload = getJwtPayload(jwt);

      // Masquerade
      const masqueradeData = getQueryParameter('m');
      if (masqueradeData) {
        // Check query parameter first
        const institution = decodeMasqueradeData(masqueradeData);
        startMasquerading(institution);
        history.replaceState({}, '', '/');
        Logger.log('Masquerade data found in query parameter.');
      } else {
        // Check session storage if no masquerade data is in the query param
        const institution = sessionStorage.getItem('masquerade');
        if (institution) {
          startMasquerading(JSON.parse(institution));
          Logger.log('Masquerade data found in session storage.');
        }
      }

      if (Date.now() / 1000 < payload.exp) {
        Logger.log('Valid JWT found in cookies, logging in...');
        setJsonWebTokenRefreshTimer();
        account.set(createAccountObjectFromPayload(payload));
        isAuthenticated.set(true);
        sendUnityJsonWebTokenMessage();
        navigate('/dashboard');
      } else {
        Logger.log('JWT expired (detected client-side)!');
        errorMessage.set('You have been logged out due to inactivity. Please sign in again.');
        Cookies.remove('jwt');
        isAuthenticated.set(false);
        stopMasquerading();
        sendUnityLogoutMessage();
      }
    }

    const error = getQueryParameter('error');
    if (error) {
      if (error === 'role') {
        errorMessage.set('Your account role has been changed and it requires you to sign in again.');
      } else if (error === 'inactive') {
        errorMessage.set('Your account is inactive. Contact your administrator for more details.');
      } else if (error === 'expired') {
        errorMessage.set('You have been logged out due to inactivity. Please sign in again.');
      }
      Cookies.remove('jwt');
      isAuthenticated.set(false);
      stopMasquerading();
      sendUnityLogoutMessage();
      navigate(window.location.pathname, { replace: true });
    }
  });

  async function authenticateHandler(event) {
    errorMessage.set('');
    loginButtonDisabled = true;

    try {
      // https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-timespan-format-strings
      let validDuration = '1:00:00'; // 1 hour
      if (keepMeLoggedIn) {
        validDuration = '30:00:00:00'; // 30 days
      }
      const json = await authenticate({ email, password, validDuration });
      Cookies.set('jwt', json.data.jsonWebToken);
      setJsonWebTokenRefreshTimer();
      const payload = getJwtPayload(json.data.jsonWebToken);
      account.set(createAccountObjectFromPayload(payload));
      isAuthenticated.set(true);
      sendUnityJsonWebTokenMessage();
      const redirectTo = getQueryParameter('redirectTo');
      navigate(redirectTo ? redirectTo : '/dashboard');
    } finally {
      loginButtonDisabled = false;
    }
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    <UnauthenticatedPageHeader>Sign in to enter.</UnauthenticatedPageHeader>

    <UnauthenticatedPageErrorBanner />

    <!-- Using a real form will submit on enter -->
    <form>
      <UnauthenticatedPageTextField
        id="email-field"
        invalid={Boolean($errorMessage)}
        bind:value={email}
        type="email"
        label="Email Address"
        withLeadingIcon
      >
        <Icon class="material-icons">person</Icon>
      </UnauthenticatedPageTextField>

      <UnauthenticatedPageTextField
        id="password-field"
        type="password"
        invalid={Boolean($errorMessage)}
        bind:value={password}
        label="Password"
        withLeadingIcon
      >
        <Icon class="material-icons">lock</Icon>
      </UnauthenticatedPageTextField>

      <UnauthenticatedPageRow>
        <div class="keep-me-logged-in">
          <FormField inputId="keep-me-logged-in-field">
            <Checkbox bind:checked={keepMeLoggedIn} />
            <div slot="label">
              <span class="keep-me-logged-in__label">Keep me logged in</span>
              <br />
              <span class="keep-me-logged-in__helper-text">Do not select if this is a shared device</span>
            </div>
          </FormField>
        </div>
      </UnauthenticatedPageRow>

      <UnauthenticatedPageButton
        id="login-button"
        label="Submit"
        on:click={authenticateHandler}
        loading={loginButtonDisabled}
      />
    </form>

    <UnauthenticatedPageLink id="forgot-password-link" href="/forgot-password" label="Forgot Password" />

    {#if isUnity()}
      <div class="no-account-section">
        <UnauthenticatedPageLink
          id="how-to-register-link"
          href="/how-to-register"
          label="Don't have a GIGXR account?"
        />
      </div>
    {/if}

    <TestEnvironmentIdentifier />
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  .keep-me-logged-in [slot='label'] {
    text-align: left;
  }

  .keep-me-logged-in__label {
    font-size: 16px;
  }

  .no-account-section {
    margin-top: -2em;
  }
</style>
