<script>
  import { onDestroy, onMount } from 'svelte';
  import { generateQrCodeImageData } from '../../util/qr-codes';
  import { title, breadcrumbPaths } from '../../stores/core-store.js';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import { getQueryParameter, getMobileOperatingSystem, setQueryParameter } from '../../util/util';
  import { fetchSession } from '../../util/api/sessions';
  import { isHoloScenarios, isUnity } from '../../util/unity';
  import Button, { Label } from '@smui/button';
  import { fetchLoginQrCode, sendAppStoreAndSessionEmail } from '../../util/api/accounts';
  import { navigate } from 'svelte-routing';
  import ButtonCircularProgress from '../../components/ButtonCircularProgress.svelte';
  import { fetchFirebaseDynamicLink } from '../../util/api/client-apps';
  import { DEVELOPMENT } from '../../config';
  import { fetchVersions } from '../../util/api/versions';
  import ContentTabs from '../../components/ContentTabs.svelte';
  import QRCodeTab from '../../components/headset-login/QRCodeTab.svelte';
  import NumericCodeTab from '../../components/headset-login/NumericCodeTab.svelte';

  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
  ]);

  const INITIAL_TIMER_VALUE = 5 * 60;

  let loading = true;
  // TODO: Remove sessionId usage. This functionality has been moved to /sessions/join/:sessionId -> SessionJoin
  let sessionId = null;
  let session;
  let imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  let emailSending = false;
  let emailSent = false;
  let joinSessionInAppDynamicLink = '';
  let qrCode = '';
  let environment = DEVELOPMENT ? 'QA' : 'PR';
  let activeTab = 0;
  let tabQuery = 'qr';

  const mobileOperatingSystem = getMobileOperatingSystem();
  let supportedVersions = [];
  let timer = 5 * 60;
  let intervalId;
  let payload = '';
  let timerStartTime = 0;

  onMount(async () => {
    let versions = [];
    sessionId = getQueryParameter('sessionId');

    tabQuery = getQueryParameter('tab');
    if (tabQuery) {
      if (tabQuery === 'code') {
        activeTab = 1;
      } else {
        setQueryParameter('tab', 'qr');
        activeTab = 0;
      }
    } else {
      setQueryParameter('tab', 'qr');
    }

    let sessionPromise = Promise.resolve();
    if (sessionId) {
      sessionPromise = fetchSession(sessionId);
      payload = `JOIN SESSION ${sessionId}`;
    }

    [qrCode, session, versions] = await Promise.all([fetchLoginQrCode(payload), sessionPromise, fetchVersions()]);

    if (sessionId) {
      title.set(`Join ${session.sessionName}`);
      breadcrumbPaths.update((paths) => [
        ...paths,
        {
          name: 'Session List',
          location: '/sessions/list',
        },
        {
          name: `Join ${session.sessionName}`,
          location: '/',
        },
      ]);
    } else {
      title.set('Headset Login');
      breadcrumbPaths.update((paths) => [
        ...paths,
        {
          name: 'Headset Login',
          location: '/headset-login',
        },
      ]);
    }

    supportedVersions = getSupportedVersions(session, versions);

    imageSrc = await generateQrCodeImageData(String(qrCode));

    if (!isUnity() && (mobileOperatingSystem === 'Android' || mobileOperatingSystem === 'iOS') && session) {
      joinSessionInAppDynamicLink = await fetchFirebaseDynamicLink(
        session.clientAppId,
        `/sessions/lobby/${session.sessionId}`,
      );
    }
    loading = false;
    startTimer();
  });

  onDestroy(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  function startTimer() {
    timerStartTime = Date.now();
    intervalId = setInterval(async () => {
      const elapsed = Math.floor((Date.now() - timerStartTime) / 1000);
      const remainingTime = INITIAL_TIMER_VALUE - elapsed;

      if (remainingTime > 0) {
        timer = remainingTime;
      } else {
        loading = true;
        timerStartTime = Date.now();
        timer = INITIAL_TIMER_VALUE;
        qrCode = await fetchLoginQrCode(payload);
        imageSrc = await generateQrCodeImageData(String(qrCode));
        loading = false;
      }
    }, 1000);
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  }

  function getSupportedVersions(session, versions) {
    try {
      const versionPrefix = session.clientAppVersion.split('.').slice(0, 3).join('.');
      return versions
        .filter((version) => version.startsWith(versionPrefix))
        .sort((a, b) => {
          let i;
          const aParts = a.split('.').map(Number);
          const bParts = b.split('.').map(Number);
          return (i = bParts.findIndex((part, index) => part !== aParts[index])) !== -1
            ? bParts[i] - (aParts[i] || 0)
            : 0;
        });
    } catch (error) {
      console.error('Error getting latest version:', error);
      return null;
    }
  }

  async function findLatestAvailableVersion() {
    let latestAvailableVersion = null;
    for (const version of supportedVersions) {
      const url = `https://controlpanel.gigxr.dev/controlpanel/${environment}/${version}/index.html`;
      try {
        const response = await fetch(url, { method: 'HEAD' });
        console.log(`Checking URL for version ${version}:`, url, response);
        if (response.ok) {
          console.log(`Found version ${version} at ${url}`);
          latestAvailableVersion = version;
          break;
        }
      } catch (error) {
        console.error(`Error checking URL for version ${version}:`, error);
      }
    }
    return latestAvailableVersion;
  }

  function handleClick() {
    findLatestAvailableVersion()
      .then((version) => {
        if (!version) throw new Error('No suitable version found');
        console.log('Found latest available version:', version);
        let url = `https://controlpanel.gigxr.dev/controlpanel/${environment}/${version}/index.html?authCode=${qrCode}&sessionId=${session.sessionId}`;
        window.open(url, '_blank');
      })
      .catch((error) => {
        console.error('Error finding a suitable version for controlpanel:', error);
        alert('There is no control panel available for this session version.');
      });
  }

  async function sendAppStoreAndSessionEmailHandler(event) {
    emailSent = false;
    emailSending = true;
    await sendAppStoreAndSessionEmail(sessionId);
    emailSent = true;
    emailSending = false;
  }

  function handleTabChange(id) {
    setQueryParameter('tab', id);
  }
</script>

<PrimaryContent>
  <div class="qr-content">
    <ContentTabs
      items={[{ label: 'QR code', component: QRCodeTab, id: 'qr' }, { label: 'Numeric Code', component: NumericCodeTab, id: 'code' }]}
      props={{ imageSrc, loading, withSession: !!session, qrCode, timer: formatTime(timer) }}
      {activeTab}
      {handleTabChange}
    />
    {#if session}
      <!-- prettier-ignore -->
      <p>
        Scan the QR Code as prompted on your device to join session
        <span class="session-name">{session.sessionName}</span>.
      </p>

      <div class="headset-join-session__wrapper">
        <!-- ControlPanel should only be visable for HoloScenarios Sessions -->
        {#if isHoloScenarios(session.clientAppId) && !isUnity()}
          <div class="headset-join-session__card desktop-only">
            <div class="headset-join-session__title">
              <img src="/icons/laptop-icon.svg" alt="HoloScenarios Web Beta" width="35" height="22" />
              <span>HoloScenarios Web Beta</span>
            </div>
            <span>Join this session on your web browser using the new HoloScenarios Web Beta</span>
            <Button id="join-session-in-app-button" variant="unelevated" class="gigxr-button" on:click={handleClick}>
              <Label>Join session</Label>
            </Button>
          </div>
        {/if}
        <div class="headset-join-session__card">
          <div class="headset-join-session__title">
            <img src="/icons/mobile-icon.svg" alt="HoloScenarios Web Beta" width="16" height="22" />
            <span>Open on Mobile</span>
          </div>
          <span>Receive links for mobile app download and session access via email</span>
          <Button
            id="email-app-store-and-session-links-button"
            on:click={sendAppStoreAndSessionEmailHandler}
            variant="unelevated"
            class="gigxr-button"
            disabled={emailSending || emailSent}
          >
            {#if emailSent}
              <Label>Email sent!</Label>
            {:else if emailSending}
              <ButtonCircularProgress />
            {:else}
              <Label>Send links</Label>
            {/if}
          </Button>
        </div>
      </div>
    {/if}
  </div>
</PrimaryContent>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if !isUnity() && (mobileOperatingSystem === 'Android' || mobileOperatingSystem === 'iOS')}
      <div class="headset-login-mobile-app-section">
        <div class="or-triangle">
          <div class="or-triangle__background-border" />
          <div class="or-triangle__background" />
          <div class="or-triangle__text">Or</div>
        </div>

        <h2>Use the GIGXR Mobile App</h2>

        <Button
          id="join-session-in-app-button"
          variant="unelevated"
          class="gigxr-button"
          on:click={() => navigate(joinSessionInAppDynamicLink)}
        >
          <Label>Join Session in App</Label>
        </Button>

        <p><strong>If you do not have the app installed you will be prompted to download it first.</strong></p>
      </div>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .session-name {
    font-weight: 700;
    color: var(--gigxr-theme-secondary-2c);
  }

  .or-triangle {
    margin-top: -18px;
    position: relative;
  }

  .or-triangle__background-border {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid var(--gigxr-theme-primary-1a);
    width: 0;
    height: 0;
    position: absolute;
    top: -20px;

    left: calc(50% - 25px);
    transform: translateY(-50%);
  }

  .or-triangle__background {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top: -22px;
    left: calc(50% - 25px);
    transform: translateY(-50%);
  }

  .or-triangle__text {
    position: absolute;
    top: 10px;
    width: 50px;
    left: calc(50% - 25px);
    transform: translateY(-50%);
    margin-top: -42px;
    font-weight: 700;
  }

  .headset-login-mobile-app-section {
    text-align: center;
  }

  .headset-login-mobile-app-section h2 {
    color: var(--gigxr-theme-secondary-2c);
    padding-top: 0.25em;
    font-size: 1.25em;
    margin-top: 20px;
  }

  .qr-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 1em;
  }

  h3 {
    margin-top: 0;
  }

  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 30px;
    min-height: 300px;
  }

  .qr-wrapper img {
    width: 100%;
  }

  .headset-join-session__wrapper {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7rem;
  }

  .headset-join-session__card {
    display: flex;
    flex-direction: column;
    padding: 1.875rem;
    background: #e5eaef;
    border-radius: 0.625rem;
    gap: 1rem;
    max-width: 27rem;
    text-align: start;
  }

  @media (max-width: 1100px) {
    .desktop-only {
      display: none;
    }

    .headset-join-session__card {
      max-width: unset;
    }
  }

  .headset-join-session__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    .headset-login-mobile-app-section {
      display: none;
    }

    .qr-wrapper {
      margin: 0 auto 30px;
      width: 300px;
      height: 300px;
    }

    .qr-wrapper img {
      width: 250px;
    }
  }
</style>
